import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './UserDetails.module.scss'
import { UserData } from '../my-account-info/models';
import { changeEmail, changePassword, getUserDetails } from '../my-account-info/services';
import { ChangePasswordDto } from '../my-account-info/models/change-password.model';
import { toast } from 'react-toastify';
import { ChangeEmailDto } from '../my-account-info/models/change-email.model';
import { boolean } from 'yup';
import { error } from 'console';

export default function UserDetails() {
    const { t, i18n } = useTranslation('translation');
    const [userDetails, setUserDetails] = useState<UserData | undefined>(undefined);
    const [changePasswordClicked, setChangePasswordClicked] = useState<boolean>(false);
    const [changeEmailClicked, setChangeEmailClicked] = useState<boolean>(false);
    const [newEmail, setNewEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [passwordMatch, setPasswordMatch] = useState<boolean>(false);

    //#region Errors
    const [newEmailError, setNewEmailError] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
    const [repeatPasswordError, setRepatPasswordError] = useState<boolean>(false);
    const [passwordMatchError, setPasswordMatchError] = useState<boolean>(false);
    //#endregion

    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = () => {
        getUserDetails().then((response) => setUserDetails(response.data));
    };

    const handleSaveButtonClick = () => {
        if (changeEmailClicked && isNewEmailFormValid()) {
            const dto: ChangeEmailDto = {
                email: newEmail,
                password: password
            }
            changeEmail(dto).then((response) => {
                if (response.status == 204) {
                    toast.success(t("myAccountInfo.userDetails.emailChangedSuccess"));
                    setNewEmail('');
                    setPassword('');
                    resetErrors();
                    getUserData();
                }
            }).catch(() => toast.error(t("myAccountInfo.userDetails.emailAlreadyInUse")));
        }
        if (changePasswordClicked && isNewPasswordFormValid()) {
            const dto: ChangePasswordDto = {
                currentPassword: currentPassword,
                newPassword: newPassword
            }
            changePassword(dto).then((response) => {
                if (response.status == 204) {
                    toast.success("Password changed successfully");
                    setNewPassword('');
                    setRepeatPassword('');
                    setCurrentPassword('');
                    resetErrors();
                }
            }).catch(() => toast.error(t("myAccountInfo.userDetails.passwordChangeError")));
        }
    }

    function isNewEmailFormValid(): boolean {
        if (isValidEmail(newEmail)) {
            setNewEmailError(false);
        } else {
            setNewEmailError(true);
        }
        if (password !== '') {
            setPasswordError(false);
        } else {
            setPasswordError(true);
        }

        return isValidEmail(newEmail) && password !== '';
    }

    function isValidEmail(email: string): boolean {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailPattern.test(email);
    }

    function isNewPasswordFormValid(): boolean {
        if (newPassword !== '') {
            setNewPasswordError(false);
        } else {
            setNewPasswordError(true);
        }
        if (repeatPassword !== '') {
            setRepatPasswordError(false);
        } else {
            setRepatPasswordError(true);
        } if (newPassword === repeatPassword) {
            setPasswordMatchError(false);
        } else {
            setPasswordMatchError(true);
        }

        return newPassword !== '' && repeatPassword !== '' && (newPassword === repeatPassword);
    }

    function resetErrors(): void {
        setNewEmailError(false);
        setPasswordError(false);
        setNewPasswordError(false);
        setRepatPasswordError(false);
        setPasswordMatchError(false);
    }

    return (
        <div className={styles.user_details_container}>
            <p className={styles.title}>{t("myAccountInfo.userDetails.title")}</p>
            <div className={styles.user_data_container}>
                <div className={styles.double_section}>
                    <div className={styles.section}>
                        <p className={styles.section_subtitle}>{t("myAccountInfo.personalData.title")}</p>
                        <div className={styles.section_data}>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.personalData.firstName")}:</p>
                                <p className={styles.data_value}>{userDetails?.user?.firstName}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.personalData.lastName")}:</p>
                                <p className={styles.data_value}>{userDetails?.user?.lastName}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.personalData.email")}:</p>
                                <p className={styles.data_value}>{userDetails?.user?.email}</p>
                            </div>
                            <div className={styles.checkbox_row}>
                                <input type="checkbox" onChange={() => { setChangeEmailClicked(!changeEmailClicked); resetErrors(); }} />
                                <p className={styles.data_value}>{t("myAccountInfo.userDetails.changeEmail")}</p>
                            </div>
                            <div className={styles.checkbox_row}>
                                <input type="checkbox" onChange={() => { setChangePasswordClicked(!changePasswordClicked); resetErrors(); }} />
                                <p className={styles.data_value}>{t("myAccountInfo.userDetails.changePassword")}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.section_data}>
                            {
                                changeEmailClicked &&
                                <>
                                    <p className={styles.section_subtitle}>{t("myAccountInfo.userDetails.changeEmail")}</p>
                                    <div className={styles.input_row}>
                                        <p className={styles.data_name}>{t("myAccountInfo.userDetails.newEmail")}:</p>
                                        <input type="text" value={newEmail} onChange={event => setNewEmail(event.target.value)} />
                                    </div>
                                    {newEmailError && <p className={styles.error}>{t("myAccountInfo.userDetails.errors.newEmail")}</p>}
                                    <div className={styles.input_row}>
                                        <p className={styles.data_name}>{t("myAccountInfo.userDetails.password")}:</p>
                                        <input type="password" value={password} onChange={event => setPassword(event.target.value)} />
                                    </div>
                                    {passwordError && <p className={styles.error}>{t("myAccountInfo.userDetails.errors.password")}</p>}
                                </>
                            }
                            {
                                changePasswordClicked &&
                                <>
                                    <p className={styles.section_subtitle}>{t("myAccountInfo.userDetails.changePassword")}</p>
                                    <div className={styles.input_row}>
                                        <p className={styles.data_name}>{t("myAccountInfo.userDetails.newPassword")}:</p>
                                        <input type="password" value={newPassword} onChange={event => setNewPassword(event.target.value)} />
                                    </div>
                                    {newPasswordError && <p className={styles.error}>{t("myAccountInfo.userDetails.errors.newPassword")}</p>}
                                    <div className={styles.input_row}>
                                        <p className={styles.data_name}>{t("myAccountInfo.userDetails.repeatPassword")}:</p>
                                        <input type="password" value={repeatPassword} onChange={event => setRepeatPassword(event.target.value)} />
                                    </div>
                                    {repeatPasswordError && <p className={styles.error}>{t("myAccountInfo.userDetails.errors.repeatPassword")}</p>}
                                    <div className={styles.input_row}>
                                        <p className={styles.data_name}>{t("myAccountInfo.userDetails.currentPassword")}:</p>
                                        <input type="password" value={currentPassword} onChange={event => setCurrentPassword(event.target.value)} />
                                    </div>
                                    {passwordMatchError && <p className={styles.error}>{t("myAccountInfo.userDetails.errors.passwordMatch")}</p>}
                                </>
                            }
                            {
                                (changeEmailClicked || changePasswordClicked) &&
                                <>
                                    <div className={styles.btn_container}>
                                        <button className={styles.submit_button} onClick={handleSaveButtonClick}>{t("myAccountInfo.userDetails.save")}</button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
