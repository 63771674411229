import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ClientPanel.module.scss';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

export default function ClientPanel() {
    const naviagte = useNavigate();
    const { t, i18n } = useTranslation('translation');

    return (
        <div className={styles.wrapper}>
            <div className={styles.main_container}>
            </div>
        </div>
    )
}
