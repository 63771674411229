import { t } from "i18next";
import styles from "./ResetPassword.module.scss";



export default function ResetPasswordComponent() {
    return (
        <div className={styles.reset_password}>
            <a href={"reset-password"}>{t("loginForm.resetPassword")}</a>
        </div>
    )
}