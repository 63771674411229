import { Filters } from "../models/filters.model";

// Currency actions
export const SET_CURRENCY = 'SET_CURRENCY';

export interface SetCurrencyAction {
    type: typeof SET_CURRENCY;
    payload: string;
}

export type CurrencyAction = SetCurrencyAction;

export const setCurrency = (name: string): SetCurrencyAction => ({
    type: SET_CURRENCY,
    payload: name,
});

// Filters actions
export const SET_FILTERS = 'SET_FILTERS';

export interface SetFiltersAction {
    type: typeof SET_FILTERS;
    payload: Filters;
}

export type FiltersAction = SetFiltersAction;

export const setFilters = (filters: Filters): SetFiltersAction => ({
    type: SET_FILTERS,
    payload: filters,
});


