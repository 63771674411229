import axios from 'axios';
import { CartItem } from '../../../../cart/models';
import { PickupPoint } from '../../../../../shared/map/models';

export async function getUserOrders() {
    return axios.get("Orders");
}

export async function getOrderById(id: string) {
    return axios.get("Orders/" + id);
}

export async function getDeliveryById(id: string) {
    return axios.get("Orders/Delivery/" + id);
}

export async function getPaymentById(id: string) {
    return axios.get("Orders/Payment/" + id);
}

export async function getAvailablePickupPointsForWebshopOrder(items: CartItem[]) {
    return axios.post("Orders/GetAvailablePickupPointsForWebshopOrder", items);
}

export async function getOrderDetails(id: string) {
    return axios.get(`Orders/GetOrderDetails/${id}`)
}