import styles from './login.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePluginStore } from 'react-pluggable';

export default function Login() {
    const { t, i18n } = useTranslation('translation');
    const navigate = useNavigate();
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");

    return (
        <div className={styles.main_container}>
            <Renderer placement="loginAndRegistration:header" />
            <Renderer placement="loginForm:inside" />
        </div>
    )
}
