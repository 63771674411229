import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import translation_en from "./translations/en/translation.json";
import translation_pl from "./translations/pl/translation.json";
import translation_nl from "./translations/nl/translation.json";
import translation_chi from "./translations/chi/translation.json";
import translation_de from "./translations/de/translation.json";
import store from './store/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

const setInitLanguage = (): string => {
    localStorage.setItem("lang", "pl");
    return "pl";
}

i18next.init({
    interpolation: { escapeValue: false },
    lng: localStorage.getItem("lang") ? localStorage.getItem("lang") as string : setInitLanguage(),
    resources: {
        en: {
            translation: translation_en
        },
        nl: {
            translation: translation_nl
        },
        chi: {
            translation: translation_chi
        },
        de: {
            translation: translation_de
        },
        pl: {
            translation: translation_pl
        },
    },
    react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    }
});
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </Provider>
    </BrowserRouter>
);
