import styles from "./HeaderNavigation.module.scss";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HeaderNavigationComponent = () => {
    const { t, i18n } = useTranslation('translation');
    const navigate = useNavigate();

    return (
        <div className={styles.navigate_buttons}>
            <div className={styles.login}>
                {t("loginForm.login")}
            </div>
            <div className={styles.register} onClick={() => navigate("/register")}>
                {t("loginForm.register")}
            </div>
        </div>
    )
}
export default HeaderNavigationComponent