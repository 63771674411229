import { IPlugin, PluginStore } from "react-pluggable";
import ResetPasswordComponent from "./components/ResetPassword/ResetPassword";


class ResetPasswordPlugin implements IPlugin {
    pluginStore!: PluginStore;

    getPluginName(): string {
        return "ResetPassword@1.0.0"
    }
    getDependencies(): string[] {
        return [];
    }
    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }
    activate(): void {
        this.pluginStore.executeFunction("Renderer.add", "resetPassword:inside", () => (
            <ResetPasswordComponent></ResetPasswordComponent>
        ));
    }
    deactivate(): void {
    }

}

export default ResetPasswordPlugin;