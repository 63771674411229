import React from 'react';
import styles from './SetNewPassword.module.scss';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as resetPasswordService from '../../../services/reset-password.service';
import * as authService from '../../../services/auth.service';
import * as yup from "yup";
import { UserLogin } from '../../../login/models';
import { SetNewPasswordDataDto } from '../../models';
import { useNavigate } from 'react-router-dom';

function assignEmailAndTokenToRequest(params: URLSearchParams, requestModel: SetNewPasswordDataDto) {
    requestModel.email = params.get("email");
    requestModel.token = params.get("token");
}

const validationSchema = yup.object({
    password: yup
        .string()
        .required('Password is required'),
    confirmPassword: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref('password')], 'Passwords must match')
});

export default function SetNewPassword() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('translation');

    function confirmPasswordChange(values: SetNewPasswordDataDto) {
        resetPasswordService.confirmNewPassword(values).then((response) => {
            if (response.status === 204) {
                const logInData: UserLogin = { loginIdentifier: values.email as string, password: values.password };
                authService.logIn(logInData, navigate, '/');
            }
        })
    }

    const params = new URLSearchParams(window.location.search);
    let requestModel: SetNewPasswordDataDto = { password: "", confirmPassword: "", token: "", email: "" };
    assignEmailAndTokenToRequest(params, requestModel);

    return (
        <div className={styles.wrapper}>
            <div className={styles.main_container}>
                <Formik validateOnMount initialValues={{ ...requestModel }}
                    onSubmit={(values) =>
                        confirmPasswordChange(values)
                    }
                    validationSchema={validationSchema}
                    initialTouched={{ password: true, confirmPassword: true }}>
                    {({ values, errors, handleChange, handleBlur }) => (
                        <Form className={styles.form_container} onMouseEnter={() => { }}>
                            <div className={styles.form_row}>
                                <p className={styles.row_name}>{t("resetPasswordForm.newPassword")}</p>
                                <input
                                    className={styles.row_input}
                                    name="password"
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className={styles.errors}>{errors.password}</div>
                            <div className={styles.form_row}>
                                <p className={styles.row_name}>{t("resetPasswordForm.confirmPassword")}</p>
                                <input
                                    className={styles.row_input}
                                    name="confirmPassword"
                                    type="password"
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className={styles.errors}>{errors.confirmPassword}</div>
                            {
                                !errors.password && !errors.confirmPassword &&
                                <div className={styles.form_row}>
                                    <button className={styles.submit_button} type="submit">{t("resetPasswordForm.setNewPassword")}</button>
                                </div>
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
