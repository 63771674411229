import { IPlugin, PluginStore } from "react-pluggable";
import { Store } from "redux";
import ProductsImageCarousel from "../components/products-page/components/product-image-carousel/ProductsImageCarousel";

class SliderPlugin implements IPlugin {

    pluginStore!: PluginStore;

    getPluginName(): string {
        return "Slider@1.0.0";
    }

    getDependencies(): string[] {
        return [];
    }

    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }

    activate(): void {
        this.pluginStore.executeFunction("Renderer.add", "page:cms", () => (
            <ProductsImageCarousel></ProductsImageCarousel>
        ));
    }

    deactivate(): void {

    }
}

export default SliderPlugin;