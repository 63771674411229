import { useEffect, useState } from 'react';
import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import { getWebshopFooter } from '../../../containers/cms-container/services';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';

const getFooter = (languageCode: string): any => getWebshopFooter(languageCode);

export default function Footer() {
    const { t, i18n } = useTranslation('translation');
    const [footer, setFooter] = useState<string>();
    const { language } = useParams();

    const getLanguageCode = (): string | null => localStorage.getItem("lang");

    useEffect(() => {
        const languageCode = getLanguageCode();
        if (languageCode) {
            getFooter(languageCode).then((response: any) => {
                setFooter(response.data);
            })
        }
    }, [])

    const renderSections = () => {
        if (!footer) return null;

        const sections = footer.split('<h3>');

        return sections.map((section, index) => {
            if (index === 0) return null;
            const [title, content] = section.split('</h3>');
            return (
                <div key={index} className={styles.footer_section}>
                    <h3>{title}</h3>
                    {parse(content)}
                </div>
            );
        });
    };

    return (
        <div className={styles.container}>

        </div>
    )
}
