import React from 'react';
import styles from './ResetPassword.module.scss';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { TextField } from '@mui/material';
import * as resetPasswordService from '../../../services/reset-password.service';
import { toast } from 'react-toastify';
import { ResetPasswordDataDto } from '../../models';

const setNewPasswordPageUrl = process.env.REACT_APP_RESET_PASSWORD_PAGE_URL as string;

function sendLink(values: any, actions: any, message: string) {
    const requestModel = {
        email: values.email,
        url: setNewPasswordPageUrl
    } as ResetPasswordDataDto;
    resetPasswordService.sendLink(requestModel).then((response) => {
    if(response) {
        toast.success(message, {
            position: toast.POSITION.TOP_CENTER
        });
        actions.resetForm();
    }
   });  
}

export default function ResetPassword() {
    const { t, i18n } = useTranslation('translation');
    return (
    <div className={styles.wrapper}>
        <div className={styles.main_container}>
            <Formik initialValues={{ email: "" }} onSubmit={(values, actions) =>
                sendLink(values, actions, t("resetPasswordForm.successMessage"))}>
                {({ values, handleChange, handleBlur }) => (
                    <Form className={styles.form_container} onMouseEnter={() => {}}>
                        <div className={styles.form_row}>
                            <p className={styles.row_name}>{t("resetPasswordForm.email")}</p>
                            <input
                                className={styles.row_input}
                                name="email"
                                type="text"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                        <div className={styles.link_container}>
                            <a href={"login"}>{t("resetPasswordForm.goBackToLoginPage")}</a>
                        </div>
                        <div className={styles.form_row}>
                            <button className={styles.submit_button} type="submit">{t("resetPasswordForm.sendLink")}</button>
                        </div>
                    </Form>
                )}
            </Formik>
            
        </div>
    </div>
    )
}
