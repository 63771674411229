import { useEffect, useRef, useState } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import styles from './AppContainer.module.scss';
import Login from '../../auth/login/Login';
import Register from '../../auth/register/Register';
import Header from '../../shared/header/Header';
import ClientPanel from '../../components/client-panel/ClientPanel';
import OrdersHistory from '../../components/client-panel/components/orders-history/OrdersHistory';
import UserDetails from '../../components/client-panel/components/user-details/UserDetails';
import { usePluginStore } from 'react-pluggable';
import {
    ABOUT, CONTACT, HOW_TO_BUY, JOIN_US, PRIVACY_POLICY, PRZELEWY24_OBLIGATIONS,
    PRZELEWY24_REGULATIONS, REGULATIONS
} from '../../shared/constants/cms-codes.constants';
import { CmsTitlesAndUrls } from '../cms-container/models/cms-titles-url-by-code';
import { getCmsUrlAndTitleByLanguagAndCode } from '../cms-container/services';
import OrderTransactionConfirmation from '../../components/order-transaction-confirmation/OrderTransactionConfirmation';
import Przelewy24PaymentPage from '../../components/przelewy24-payment-page/Przelewy24PaymentPage';
import ContinueOrCreateAccount from '../continue-or-create-account/ContinueOrCreateAccount';

export default function AppContainer() {
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");
    const [cartItemsChanged, setCartItemsChanged] = useState<boolean>(false);
    const [titlesAndUrls, setTitlesAndUrls] = useState<CmsTitlesAndUrls>({});
    const [loading, setLoading] = useState(true);
    const cmsContainerRef = useRef<HTMLElement | null>(null);
    const location = useLocation();
    const [cmsContainerLoaded, setCmsContainerLoaded] = useState(false);

    const getTitleAndUrlForHeader = async (): Promise<void[]> => {
        const codes = [HOW_TO_BUY, CONTACT, JOIN_US,
            ABOUT, PRIVACY_POLICY, PRZELEWY24_OBLIGATIONS,
            PRZELEWY24_REGULATIONS, REGULATIONS];
        const savedLanguageCode = localStorage.getItem('lang');
        if (savedLanguageCode) {
            const promises = codes.map(async (code) => {
                const response = await getCmsUrlAndTitleByLanguagAndCode(code, savedLanguageCode);
                setTitlesAndUrls((prevTitlesAndUrls) => ({
                    ...prevTitlesAndUrls,
                    [code]: response.data,
                }));
            });
            await Promise.all(promises);
            setLoading(false);
        }

        return Promise.resolve([]);
    };


    useEffect(() => {
        getTitleAndUrlForHeader();
    }, [localStorage.getItem('lang')]);

    useEffect(() => {
    }, [loading, titlesAndUrls]);

    const scrollToCmsContainer = () => {
        if (cmsContainerRef.current) {
            window.scrollTo({
                top: cmsContainerRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        scrollToCmsContainer();
    }, [location.pathname, cmsContainerLoaded]);

    return (
        <div className={styles.main_container}>
            <div className={styles.header}>
                <Header cartItemsChanged={cartItemsChanged} />
            </div>
            <div className={styles.content}>
                <Routes>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path='/:language?/login' Component={Login} />
                    <Route path='/:language?/register' Component={Register} />
                    <Route path='/:language?/client-panel' Component={ClientPanel} >
                        <Route path='orders' element={<OrdersHistory />} />
                        <Route path='user-details' element={<UserDetails />} />
                    </Route>
                    <Route path='/:language?/register-transaction-confirmation' element={<OrderTransactionConfirmation />} />
                    <Route path='/:language?/continue-or-create-account' element={<ContinueOrCreateAccount />} />
                    <Route path='/:language?/przelewy24payment/:orderId' element={<Przelewy24PaymentPage />} />
                </Routes>
            </div>
        </div>
    );
}
