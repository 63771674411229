import { IPlugin, PluginStore } from "react-pluggable";
import LoginForm from "./components/LoginForm/LoginForm";
class LoginFormPlugin implements IPlugin {
    pluginStore!: PluginStore;

    getPluginName(): string {
        return "LoginForm@1.0.0";
    }
    getDependencies(): string[] {
        return [];
    }
    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }
    activate(): void {
        this.pluginStore.executeFunction('Renderer.add', 'loginForm:inside', () => (
            <LoginForm></LoginForm>
        ));
    }
    deactivate(): void {
    }

}

export default LoginFormPlugin;