import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './OrdersHistory.module.scss'
import { OrderModel } from '../../../order/models';
import { getUserOrders } from '../my-account-info/services';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';

export default function OrdersHistory() {

    const currency = useSelector((state: RootState) => state.currency);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('translation');
    const [orders, setOrders] = useState<OrderModel[]>();

    useEffect(() => {
        getUserOrders().then((response) => {
            setOrders(response.data);
        })
    }, []);

    return (
        <div className={styles.main_container}>
            <div className={styles.section}>
                <p className={styles.orders_title}>{t("myAccountInfo.myOrders")}</p>
                {(orders && orders?.length != 0) ?
                    <div className={styles.table_container}>
                        <table>
                            <thead>
                                <tr>
                                    <th className={styles.order}>{t("myAccountInfo.orders.order")}</th>
                                    <th className={styles.date}>{t("myAccountInfo.orders.date")}</th>
                                    <th className={styles.address}>{t("myAccountInfo.orders.address")}</th>
                                    <th className={styles.summary}>{t("myAccountInfo.orders.orderValue")}</th>
                                    <th className={styles.status}>{t("myAccountInfo.orders.status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orders && orders?.map((order: OrderModel, index: number) => (
                                        <tr key={index}>
                                            <td className={styles.order_column}>
                                                <a href={'/client-panel/orders/' + order.id} >{order.referenceNumber}</a>
                                            </td>
                                            <td className={styles.date_column}>
                                                {dayjs(order.creationDate).format('DD/MM/YYYY')}
                                            </td>
                                            <td className={styles.address_column}>
                                                <div>
                                                    <p>{order.address?.streetName + " " + order.address?.buildingNumber}</p>
                                                    <p>{order.address?.postalCode + " " + order.address?.city}</p>
                                                </div>
                                            </td>
                                            <td className={styles.summary_column}>
                                                {order.totalPrice.toFixed(2) + " " + order.currencyName}
                                            </td>
                                            <td className={styles.status_column}>
                                                {t(`orderStatuses.${order.status?.toLocaleLowerCase()}`)}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div> :
                    <div className={styles.no_orders_warning}>
                        <div className={styles.warning_content}>
                            <WarningIcon className={styles.warning_icon}></WarningIcon>
                            <p>{t("myAccountInfo.orders.warning")}</p>
                        </div>
                        <button onClick={() => navigate("/")} className={styles.go_shopping_button}>{t("myAccountInfo.orders.goShopping")}</button>
                    </div>
                }

            </div>
        </div>
    )
}
