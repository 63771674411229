import axios from "axios";
import { Przelewy24Payment } from "../../order/models/przelewy24-payment.model";
import { RequestRegisterPaymentDto } from "../models/request-register-payment.dto";

export const getPrzelewy24PaymentMethods = async (currency: string, amount: number, language: string) => {
    const response = axios.get(`OnlinePayments/Przelewy24PaymentMethods?amount=${amount}&currency=${currency}&language=${language}`);
    const paymentMethods: Przelewy24Payment = (await response).data;
    return paymentMethods;
};

export const registerPrzelewy24Transaction = async (onlinePaymentDto: RequestRegisterPaymentDto) => {
    const response = axios.post(`OnlinePayments/Przelewy24RegisterTransaction`, onlinePaymentDto);
    const url: string = (await response).data;
    return url;
};
