import { IPlugin, PluginStore } from "react-pluggable";
import HeaderNavigationComponent from "./components/HeaderNavigation/HeaderNavigation";


class HeaderNavigationPlugin implements IPlugin {
    pluginStore!: PluginStore;
    getPluginName(): string {
        return "HeaderNavigation@1.0.0"
    }
    getDependencies(): string[] {
        return []
    }
    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }
    activate(): void {
        this.pluginStore.executeFunction('Renderer.add', 'loginAndRegistration:header', () => (
            <HeaderNavigationComponent></HeaderNavigationComponent>
        ));
    }
    deactivate(): void {
    }

}

export default HeaderNavigationPlugin