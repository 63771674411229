import Header from '../../shared/header/Header';
import styles from './OrderTransactionConfirmation.module.scss';
import { useTranslation } from 'react-i18next';
import { usePluginStore } from 'react-pluggable';

export default function OrderTransactionConfirmation() {
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");
    const { t, i18n } = useTranslation('translation');


    return (

        <div className={styles.main_container}>
            <div className={styles.content}>
                <h1 className={styles.confirmation_text}>
                    <p>Transakcja w trakcie realizacji</p>
                    <p>Dziękujemy za złożenie zamówienia</p>
                </h1>
            </div>
        </div >
    );
}