import axios from 'axios';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import * as axiosConfiguration from './axios.token-configuration';
import { logOut } from '../../auth/services/auth.service';
import { useNavigate } from 'react-router-dom';
export const addErrorsInterceptor = (): void => {
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response?.status === 401 && axios.defaults.headers.common["Authorization"]) {
                const navigate = useNavigate();
                logOut(navigate);
                toast.error(t("commonErrors.sessionExpired"), {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            else if ((error.response?.status > 299) || (error.response?.status < 200)) {
                if (error.response?.status === 401) {
                    localStorage.removeItem("token");
                    axiosConfiguration.setAuthToken();
                    toast.error(t("commonErrors.badCredentials"), {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const navigate = useNavigate();
                    logOut(navigate);
                }
                else {
                    return Promise.reject(error);
                }
            }
        });
}