import { useEffect, useState } from 'react';
import styles from "./Header.module.scss";
import { Link, useNavigate } from 'react-router-dom';
import * as authService from '../../auth/services/auth.service';
import { useTranslation } from 'react-i18next';
import { getCmsUrlAndTitleByLanguagAndCode } from '../../containers/cms-container/services';
import { ABOUT, CONTACT, HOW_TO_BUY, JOIN_US } from '../constants/cms-codes.constants';
import { CmsTitlesAndUrls } from '../../containers/cms-container/models/cms-titles-url-by-code';


export default function Header(props: any) {
    const { t, i18n } = useTranslation('translation');
    const navigate = useNavigate();
    const [titlesAndUrls, setTitlesAndUrls] = useState<CmsTitlesAndUrls>({});
    const [loading, setLoading] = useState(true);


    const getTitleAndUrlForHeader = async (): Promise<void[]> => {
        const codes = [HOW_TO_BUY, CONTACT, JOIN_US, ABOUT];
        const savedLanguageCode = localStorage.getItem('lang');
        if (savedLanguageCode) {
            const promises = codes.map(async (code) => {
                const response = await getCmsUrlAndTitleByLanguagAndCode(code, savedLanguageCode);
                setTitlesAndUrls((prevTitlesAndUrls) => ({
                    ...prevTitlesAndUrls,
                    [code]: response.data,
                }));
            });
            await Promise.all(promises);
            setLoading(false);
        }

        return Promise.resolve([]);
    };


    useEffect(() => {
        getTitleAndUrlForHeader();
    }, [localStorage.getItem('lang')]);

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    function logOut() {
        authService.logOut(navigate);
    }

    return (
        <div className={styles.main_container}>
            <div className={styles.upper_part}>
                <div className={styles.upper_part_content}>
                    <div className={styles.left_side}>

                    </div>
                    <div className={styles.right_side}>
                        {
                            !authService.checkLogInStatus() ?
                                <>
                                    <span onClick={() => navigate('/register')}>{t("header.register")}</span>
                                    <span onClick={() => navigate('/login')}>{t("header.login")}</span>
                                </>
                                :
                                <>
                                    <span className={styles.blured}>{t("header.register")}</span>
                                    <span onClick={() => logOut()}>{t("header.logout")}</span>
                                </>
                        }
                    </div>
                </div>
            </div>
            <div className={styles.bottom_part}>
                <div className={`${styles.links_container} ${hamburgerOpen ? `${styles.open}` : ''}`}>
                    <Link to="/">{t("header.shop")}</Link>
                </div>
            </div>
        </div>
    )
}