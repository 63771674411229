import { t } from "i18next";
import styles from "./RememberMe.module.scss";

const RememberMeComponent = () => {

    return (
        <div className={styles.remember_me}>
            <input name="remember" className={styles.checkbox} type='checkbox' />
            <label htmlFor="remember">{t("loginForm.rememberMe")}</label>
        </div>
    )
}
export default RememberMeComponent;