import { IPlugin, PluginStore } from "react-pluggable";
import RememberMeComponent from "./components/RememberMe/RememberMe";


class RememberMePlugin implements IPlugin {
    pluginStore!: PluginStore;

    getPluginName(): string {
        return "RememberMe@1.0.0"
    }
    getDependencies(): string[] {
        return [];
    }
    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }
    activate(): void {
        this.pluginStore.executeFunction("Renderer.add", "loginOptions:inside", () => (
            <RememberMeComponent></RememberMeComponent>
        ));
    }
    deactivate(): void {
    }

}

export default RememberMePlugin