import React from 'react';
import ResetPassword from '../../auth/reset-password/components/reset-password/ResetPassword';
import { Route, Routes } from 'react-router-dom';
import SetNewPassword from '../../auth/reset-password/components/set-new-password/SetNewPassword';

export default function ResetPaswordContainer() {
  return (
    <Routes>
        <Route path="/" Component={ResetPassword}/>
        <Route path="/set-new-password" Component={SetNewPassword}/>
    </Routes>
    
  )
}
