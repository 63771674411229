import { IPlugin, PluginStore } from "react-pluggable";
import RegistrationForm from "./components/RegistrationForm/RegistrationForm";


class RegistrationFormPlugin implements IPlugin {
    pluginStore!: PluginStore;

    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }

    getPluginName(): string {
        return 'RegistrationForm@1.0.0';
    }

    getDependencies(): string[] {
        return [];
    }

    deactivate(): void { }

    activate(): void {
        this.pluginStore.executeFunction('Renderer.add', 'registrationForm:inside', () => (
            <RegistrationForm></RegistrationForm>
        ));
    }
}

export default RegistrationFormPlugin;