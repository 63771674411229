import axios from "axios"

export const getImages = () => {
    return axios.get("Images");
}

export const getSliderImages = () => {
    return axios.get("Images/Slider-Images");
}

export const getImageByAttachmentId = (imageId: string) => {
    return axios.get(`Images/${imageId}`);
};

export const downloadImage = (imageId: string) => {
    const headers = {
        'Content-Disposition': 'attachment',
        'Content-Type': 'application/octet-stream',
    };
    const response = axios.get(`/Images/attachments/download/${imageId}`, {
        responseType: 'blob',
        headers,
    });

    return response;
};
