import { combineReducers } from 'redux';
import { SET_FILTERS, FiltersAction, CurrencyAction, SET_CURRENCY } from './actions';
import { Filters } from '../models/filters.model';

// Currency reducer
export interface CurrencyState {
    name: string;
}

const currencyInitialState: CurrencyState = {
    name: ""
};

const currencyReducer = (state = currencyInitialState, action: CurrencyAction): CurrencyState | string => {
    switch (action.type) {
        case SET_CURRENCY:
            return action.payload;
        default:
            return state;
    }
};

// Filters reducer
export interface FilterState {
    filters: Filters;
}

const filtersInitialState: Filters = {
    pickupPointId: null,
    productName: null,
    categoryId: null
};

const filtersReducer = (state = filtersInitialState, action: FiltersAction): Filters => {
    switch (action.type) {
        case SET_FILTERS:
            return action.payload;
        default:
            return state;
    }
};

// Combined reducer
export default combineReducers({
    currency: currencyReducer,
    filters: filtersReducer,
});
